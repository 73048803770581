import React from "react";
import logo from "../../media/Logo/Shila-LOGO-PNG_Low-green.png";
import "./About.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import icon1 from "../../media/Icon Promo/305-1.png";
import icon2 from "../../media/Icon Promo/16290-1.png";
import icon3 from "../../media/Icon Promo/336650-PA8ZQX-707-1.png";
const About = () => {
  const wabrosur = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281295724936&text=Halo+Radja%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(brosur)%20https://www.shila-atsawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="about" className="aboutcontainer">
      <div className="aboutcenter">
        <div className="aboutlogo">
          <img className="logo" alt="shilalogo" src={logo} />
        </div>
        <div className="aboutdes">
          Shila at Sawangan adalah sebuah proyek pengembangan yang terletak di
          Depok, Jawa Barat. Proyek ini merupakan perpaduan antara pemukiman,
          perkantoran, dan area komersial. Shila at Sawangan memiliki luas lahan
          102 hektare dan dibangun di sekitar lapangan golf Sawangan yang
          merupakan lapangan golf tertua kedua di Jabodetabek.
        </div>
        <div className="aboutbutton">
          <button className="brochure" onClick={wabrosur}>
            <FontAwesomeIcon icon={faCloudArrowDown} />
            &nbsp;E-Brochure
          </button>
        </div>
        <div className="abouticon">
          <div className="divicon">
            <img alt="icon" className="icon" src={icon1} />
            <div className="des">Konsep Hunian Nyaman</div>
          </div>
          <div className="divicon">
            <img alt="icon" className="icon" src={icon2} />
            <div className="des">Fasilitas Dalam Kawasan</div>
          </div>
          <div className="divicon">
            <img alt="icon" className="icon" src={icon3} />
            <div className="des">Sistem Keamanan 24 Jam</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
