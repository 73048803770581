import tudor1 from "./28.webp";
import tudor2 from "./29.webp";
import tudor3 from "./30.webp";
import tudor4 from "./31.webp";
import tudor5 from "./32.webp";
import tudor6 from "./33.webp";
import tudor7 from "./34.webp";
import tudor8 from "./35.webp";
import tudor9 from "./36.webp";
import tudor10 from "./37.webp";
import tudor11 from "./38.webp";

const tudor = [
  tudor1,
  tudor2,
  tudor3,
  tudor4,
  tudor5,
  tudor6,
  tudor7,
  tudor8,
  tudor9,
  tudor10,
  tudor11,
];

export default tudor;
