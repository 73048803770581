import React from "react";
import "./Footer.scss";
const Footer = () => {
  return (
    <div className="footercontainer">
      <div className="footeratas">
        <div className="title">Marketing Gallery</div>
        <div className="subtitle">Shila At Sawangan</div>
        <div className="alamat">
          Jl. Raya Bojongsari No.53, Bojongsari Lama, Kec. Bojongsari, Kota
          Depok, Jawa Barat 16517
        </div>
        <div className="contact">
          Contact us : +6281295724936
          <br />
          Privacy Policy
        </div>
      </div>
      <div className="footerbawah">Shila At Sawangan | 2024</div>
    </div>
  );
};

export default Footer;
