import React from "react";
import "./Promo.scss";
import promo from "../../media/Promo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
const Promo = () => {
  const promoitem = [
    "DP 0%",
    "Free Biaya BPHTB",
    "Subsidi Biaya Akad 3%",
    "Free Smartdoor Lock",
    "Free Canopy",
    "Free SmartHome System",
    "Free CCTV",
  ];

  return (
    <div id="promo" className="promocontainer">
      <div className="promocenter">
        <div className="promoimg">
          <img className="promo" alt="promoimg" src={promo} />
        </div>
        <div className="promopoint">
          <div className="pointtitle">Promo Terkini :</div>
          <div className="point">
            <ul>
              {promoitem.map((slide, index) => (
                <li>
                  <FontAwesomeIcon color="#695638" icon={faCheckDouble} />
                  &nbsp;{slide}
                </li>
              ))}
              <li className="disclaimer">*Disclaimer</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
