import oakwood1 from "./WhatsApp-Image-2023-10-25-at-2.54.38-PM.jpeg";
import oakwood2 from "./WhatsApp-Image-2023-10-25-at-2.55.58-PM.jpeg";
import oakwood3 from "./WhatsApp-Image-2023-10-25-at-2.55.59-PM-1.jpeg";
import oakwood4 from "./WhatsApp-Image-2023-10-25-at-2.55.59-PM.jpeg";
import oakwood5 from "./WhatsApp-Image-2023-10-25-at-2.56.00-PM-1.jpeg";
import oakwood6 from "./WhatsApp-Image-2023-10-25-at-2.56.00-PM.jpeg";
import oakwood7 from "./WhatsApp-Image-2023-10-25-at-2.56.01-PM-1.jpeg";
import oakwood8 from "./WhatsApp-Image-2023-10-25-at-2.56.01-PM.jpeg";
import oakwood9 from "./WhatsApp-Image-2023-10-25-at-2.56.02-PM-1.jpeg";

const oakwood = [
  oakwood1,
  oakwood2,
  oakwood3,
  oakwood4,
  oakwood5,
  oakwood6,
  oakwood7,
  oakwood8,
  oakwood9,
];

export default oakwood;
