import terrace1 from "./10.webp";
import terrace2 from "./11.webp";
import terrace3 from "./12.webp";
import terrace4 from "./13.webp";
import terrace5 from "./14.webp";
import terrace6 from "./15.webp";
import terrace7 from "./16.webp";
import terrace8 from "./17.webp";
import terrace9 from "./18.webp";

const terrace = [
  terrace1,
  terrace2,
  terrace3,
  terrace4,
  terrace5,
  terrace6,
  terrace7,
  terrace8,
  terrace9,
];

export default terrace;
