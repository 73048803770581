import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Lokasi.scss";
import map from "../../media/map.png";

const Lokasi = () => {
  return (
    <div id="lokasi" className="lokasicontainer">
      <div className="center">
        <div className="lokasititle">LOKASI</div>
        <div className="lokasiimg">
          <img className="img" alt="map" src={map} />
        </div>
        <div className="lokasipoint">
          <ul>
            <li>
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp;10 menit dari CBD Jakarta (tol Depok-Antasari)
            </li>
            <li>
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp;15 menit dari Serpong (tol JORR 2)
            </li>
            <li>
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp;30 menit dari PIM
            </li>
            <li>
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp;5 menit dari The Park Mall
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Lokasi;
