import courtyard1 from "./1-2.webp";
import courtyard2 from "./3-2.webp";
import courtyard3 from "./4-1.webp";
import courtyard4 from "./5.webp";
import courtyard5 from "./6.webp";
import courtyard6 from "./7.webp";
import courtyard7 from "./8.webp";
import courtyard8 from "./9.webp";

const courtyard = [
  courtyard1,
  courtyard2,
  courtyard3,
  courtyard4,
  courtyard5,
  courtyard6,
  courtyard7,
  courtyard8,
];

export default courtyard;
