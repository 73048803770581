import React from "react";
import "./Product.scss";

import terrace from "../../media/Product/Terrace";
import data from "./data.json";
import Card from "../../component/Card";
import courtyard from "../../media/Product/Courtyard";
import verdant from "../../media/Product/Verdant";
import oakwood from "../../media/Product/Oakwood";
import pavilion from "../../media/Product/Pavilion";
import tudor from "../../media/Product/Tudor";
import portico from "../../media/Product/Portico";
import ecotown from "../../media/Product/Eco Town";
import Cardruko from "../../component/Cardruko";
import hannam from "../../media/Product/hannam/hannam";

const Product = () => {
  const imageMap = {
    terrace,
    courtyard,
    verdant,
    oakwood,
    pavilion,
    tudor,
    portico,
    ecotown,
    hannam,
  };

  return (
    <div>
      <div id="rumah" className="productcontainer">
        <div className="title">OUR HOME</div>
        <div className="cardproduct">
          {data.cards.map((card, index) => {
            return (
              <Card
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                details={card.details}
                price={card.price}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
        <div id="ruko" className="title">
          OUR COMMERCIAL
        </div>
        <div className="cardruko">
          {data.card.map((card, index) => {
            return (
              <Cardruko
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                details={card.details}
                price={card.price}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Product;
