import React from "react";
import "./Navbar.scss";
const Navbar = () => {
  return (
    <div className="navbarcontainer">
      <div className="navbartext">SHILA AT SAWANGAN</div>
    </div>
  );
};

export default Navbar;
