import portico1 from "./39.webp";
import portico2 from "./40.webp";
import portico3 from "./41.webp";
import portico4 from "./42.webp";
import portico5 from "./43.webp";
import portico6 from "./44.webp";
import portico7 from "./45.webp";
import portico8 from "./46.webp";
import portico9 from "./47.webp";
import portico10 from "./48.webp";
import portico11 from "./49.webp";
import portico12 from "./50.webp";

const portico = [
  portico1,
  portico2,
  portico3,
  portico4,
  portico5,
  portico6,
  portico7,
  portico8,
  portico9,
  portico10,
  portico11,
  portico12,
];

export default portico;
