import React from "react";
import Navbar from "../../section/navbar/Navbar";
import Hero from "../../section/hero/Hero";
import About from "../../section/about/About";
import Promo from "../../section/promo/Promo";
import Product from "../../section/product/Product";
import Lokasi from "../../section/lokasi/Lokasi";
import Footer from "../../section/footer/Footer";
import Newlaunch from "../../section/newlaunch/newlaunch";
import ScrollToHashElement from "./ScrollToHashElement";

const Home = () => {
  return (
    <div>
      <ScrollToHashElement />
      <Navbar />
      <Hero />
      <About />
      <Newlaunch />
      <Promo />
      <Product />
      <Lokasi />
      <Footer />
    </div>
  );
};

export default Home;
