import React from "react";
import Slider from "react-slick";
import heroimg from "../../media/Hero";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="herocontainer">
      <div className="heroslider">
        <Slider {...settings}>
          {heroimg.map((slide, index) => (
            <img
              key={index}
              className="imgcontent"
              src={slide}
              alt="Surrounding"
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Hero;
