import Verdant1 from "./Verdant.png";
import Verdant2 from "./verdant (2).png";
import Verdant3 from "./verdant (3).png";
import Verdant4 from "./verdant (4).png";
import Verdant5 from "./verdant (5).png";
import Verdant6 from "./verdant (6).png";
import Verdant7 from "./verdant (7).png";
import Verdant8 from "./verdant (8).png";
import Verdant9 from "./verdant (9).png";

const verdantimages = [
  Verdant1,
  Verdant2,
  Verdant3,
  Verdant4,
  Verdant5,
  Verdant6,
  Verdant7,
  Verdant8,
  Verdant9,
];

export default verdantimages;
