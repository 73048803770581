import pavilion1 from "./19.webp";
import pavilion2 from "./20.webp";
import pavilion3 from "./21.webp";
import pavilion4 from "./22.webp";
import pavilion5 from "./23.webp";
import pavilion6 from "./24.webp";
import pavilion7 from "./25.webp";
import pavilion8 from "./26.webp";
import pavilion9 from "./27.webp";

const pavilion = [
  pavilion1,
  pavilion2,
  pavilion3,
  pavilion4,
  pavilion5,
  pavilion6,
  pavilion7,
  pavilion8,
  pavilion9,
];

export default pavilion;
