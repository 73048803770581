import React from "react";
import "./Card.scss";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const Card = ({ title, carousel, subtitle, price, details, whatsappLink }) => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };

  return (
    <div className="card">
      <Slider {...settings}>
        {carousel.map((lt6, index) => (
          <img
            className="card-image"
            key={index}
            src={lt6}
            alt={`Launching ${index + 1}`}
          ></img>
        ))}
      </Slider>
      <div className="content-card">
        <div className="cluster-card-title">
          <div className="cluster-name">
            <div className="cluster-name-sub">{title}</div>
            <div className="cluster-name-sub2">{subtitle}</div>
          </div>
          <div className="cluster-price">
            <div className="cluster-price-sub">{price}</div>
            <div className="cluster-price-sub2">Juta / Bulan</div>
          </div>
        </div>
        <hr className="card-separator" />
        <div className="card-icon">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span>: {details.luastanah}m² </span>
          <FontAwesomeIcon icon={faHouse} />
          <span>: {details.luasrumah}m² </span>
          <FontAwesomeIcon icon={faBed} />
          <span>: {details.bed} </span>
          <FontAwesomeIcon icon={faShower} />
          <span>: {details.bath} </span>
        </div>
        <div className="card-button">
          <button
            className="button"
            onClick={() => window.open(whatsappLink, "_blank")}
          >
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
