import React from "react";
import "./newlaunch.scss";
import nlimage from "./newlaunch.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faLocationDot,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const newlaunch = () => {
  const nlwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281295724936&text=Halo+Radja%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(hannam)%20https://www.shila-atsawangan.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-newlaunch">
      <div id="newlaunch" className="judul-new">
        NEW LAUNCHING
      </div>
      <div className="img-new">
        <img src={nlimage} alt="newlaunching" className="gambar" />
      </div>
      <div className="detail-new">
        <div className="judul-detail">Hannam</div>
        <div className="desk-detail">
          <div className="lokasi-detail">
            <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
          </div>
          <div className="list-detail">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            <span> : 77m²</span>
            <FontAwesomeIcon icon={faHouse} />
            <span> : 95m²</span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 3+1</span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 3+1</span>
          </div>
        </div>
        <div className="button-nl">
          <button onClick={nlwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default newlaunch;
