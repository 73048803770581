import Hannam1 from "./hannam (1).png";
import Hannam2 from "./hannam (2).png";
import Hannam3 from "./hannam (3).png";
import Hannam4 from "./hannam (4).png";
import Hannam5 from "./hannam (5).png";
import Hannam6 from "./hannam (6).png";
import Hannam7 from "./hannam (7).png";
import Hannam8 from "./hannam (8).png";
import Hannam9 from "./hannam (9).png";
import Hannam10 from "./hannam (10).png";
import Hannam11 from "./hannam (11).png";
import Hannam12 from "./hannam (12).png";
import Hannam13 from "./hannam (13).png";
import Hannam14 from "./hannam (14).png";
import Hannam15 from "./hannam (15).png";
import Hannam16 from "./hannam (16).png";
import Hannam17 from "./hannam (17).png";
import Hannam18 from "./hannam (18).png";
import Hannam19 from "./hannam (19).png";

const hannam = [
  Hannam1,
  Hannam2,
  Hannam3,
  Hannam4,
  Hannam5,
  Hannam6,
  Hannam7,
  Hannam8,
  Hannam9,
  Hannam10,
  Hannam11,
  Hannam12,
  Hannam13,
  Hannam14,
  Hannam15,
  Hannam16,
  Hannam17,
  Hannam18,
  Hannam19,
];

export default hannam;
