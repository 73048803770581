import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6281295724936&text=Halo+Radja%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(wafloating)%20https://www.shila-atsawangan.com//&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default App;
