import ecotown1 from "./Copy-of-02-07-23-5.jpg";
import ecotown2 from "./Copy-of-04-07-23-01-5.jpg";
import ecotown3 from "./Copy-of-04-07-23-02-4.jpg";
import ecotown4 from "./Copy-of-04-07-23-03-3.jpg";
import ecotown5 from "./Copy-of-06-07-23-3.jpg";
import ecotown6 from "./level-1.png";
import ecotown7 from "./level-2.png";
import ecotown8 from "./level-3.png";

const ecotown = [
  ecotown1,
  ecotown2,
  ecotown3,
  ecotown4,
  ecotown5,
  ecotown6,
  ecotown7,
  ecotown8,
];

export default ecotown;
